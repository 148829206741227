import { ComponentSlider } from '@emico-react/component-slider'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { globalWindow } from '@emico-utils/ssr-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { ComponentProps, useEffect, useRef, useState } from 'react'

import { maxWidth, minWidth } from '@emico/styles'
import { Loader } from '@emico/ui'

import BlogPagination from '../../../components/BlogPagination'
import Container from '../../../components/Container'
import InformationCard from '../../../components/InformationCard'
import Meta from '../../../components/Meta'
import MetaCanonical from '../../../components/MetaCanonical'
import PageHeader from '../../../components/PageHeader'
import SectionHeader from '../../../components/SectionHeader'
import { CraftBlogPostCardFragment } from '../../../lib/craftBlogFragments.generated'
import getSeoPageDescription from '../../../lib/getSeoPageDescription'
import theme from '../../../theme'
import { sizesLg as themeSizes } from '../../../theme/sizes'
import { spacingDefaults as themeSpacing } from '../../../theme/spacing'
import { CraftBlogStaticData, useBlogEntries } from './useBlog'

const BLOG_PAGE_SIZE = process.env.REACT_APP_BLOG_PAGE_SIZE
  ? Number(process.env.REACT_APP_BLOG_PAGE_SIZE)
  : 9

const SCROLL_OFFSET = Math.round(
  parseInt(themeSizes.headerHeight) +
    parseInt(themeSizes.headerCollapsibleHeight) +
    parseInt(themeSpacing.lg),
)

const StyledContainer = styled(Container)`
  padding-top: ${theme.spacing.xl};
  margin-bottom: ${theme.spacing['2xl']};

  @media ${minWidth('md')} {
    padding-top: ${theme.spacing['2xl']};
  }
`

const BlogPostListWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing['2xl']};
  }
`

const PostListMobile = styled.div`
  @media ${minWidth('md')} {
    display: none;
  }
`

const PostListTablet = styled.div`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const PostListDesktop = styled.div`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const Grid = styled('div', {
  shouldForwardProp: (prop) => !['itemCount'].includes(prop.toString()),
})<{ itemCount: number }>`
  display: grid;
  grid-gap: ${theme.spacing.md};

  @media ${minWidth('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${minWidth('md')} {
    grid-template-columns: ${({ itemCount }) =>
      `repeat(${itemCount === 1 ? 2 : 3}, 1fr)`};
  }
`

const BlogPostWrapper = styled.div`
  position: relative;
`

const LoaderWrapper = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const StyledLoader = styled(Loader)`
  margin: 0;
`

const StyledBlogPagination = styled(BlogPagination)`
  margin-top: ${theme.spacing.xl};

  @media ${minWidth('md')} {
    margin-top: ${theme.spacing['2xl']};
  }
`

interface SliderProps
  extends Omit<ComponentProps<typeof ComponentSlider>, 'children'> {
  blogPosts: Array<CraftBlogPostCardFragment | null>
}

const Slider = ({ blogPosts, ...other }: SliderProps) => {
  if (!blogPosts) {
    return null
  }

  return (
    <ComponentSlider snapAlign="start" slideGap={15} {...other}>
      {blogPosts.map((post, index) => {
        const postImage = post?.image?.[0]

        if (!post?.title || !post.cardIntroduction || !postImage) {
          return null
        }

        return (
          <InformationCard
            key={index}
            title={post?.title}
            date={post?.dateCreated}
            image={postImage}
            richText={post?.cardIntroduction}
            readMoreLines={2}
            readMoreText={post?.readMoreAlternative ?? undefined}
            url={post.uri ?? undefined}
          />
        )
      })}
    </ComponentSlider>
  )
}

type Props = CraftBlogStaticData

const Blog = ({ craftDataBlog, craftDataBlogPosts }: Props) => {
  const { query } = useRouter()
  const pageParam = query.page ? Number(query.page) : undefined

  const blogGridRef = useRef<HTMLDivElement>(null)
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(
    pageParam ?? 1,
  )

  const paginationOffset = (currentPageIndex - 1) * BLOG_PAGE_SIZE
  const blogPageCount = craftDataBlogPosts
    ? Math.ceil(craftDataBlogPosts.length / BLOG_PAGE_SIZE)
    : 0

  /**
   * Retrieve dynamic blog post results, based on pagination page index.
   * Only posts within the current pagination search will be retrieved.
   */
  const { blogEntries, isLoading: isLoadingBlogEntries } = useBlogEntries(
    BLOG_PAGE_SIZE,
    paginationOffset,
  )

  const blogData =
    craftDataBlog?.__typename === 'CraftBlogOverviewEntry'
      ? craftDataBlog
      : undefined
  const headerImage = blogData?.image?.[0]
  const blogPostCardLists = blogData?.blogPostCards

  /**
   * Scroll to top of blog post overview
   * when pagination / currentPageIndex changes
   */
  useEffect(() => {
    if (pageParam && blogGridRef.current) {
      setCurrentPageIndex(currentPageIndex)

      if (!isLoadingBlogEntries) {
        globalWindow?.scrollTo({
          top: blogGridRef.current.offsetTop - SCROLL_OFFSET,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          behavior: 'instant',
        })
      }
    }
  }, [currentPageIndex, isLoadingBlogEntries, pageParam])

  /**
   * When URL pageParam changes update currentPageIndex to current pageParam
   */
  useEffect(() => {
    if (pageParam && pageParam !== currentPageIndex) {
      setCurrentPageIndex(pageParam)
    }
  }, [currentPageIndex, pageParam])

  return (
    <>
      <Meta
        metaTitle={blogData?.metaTitle ?? undefined}
        metaDescription={blogData?.metaDescription ?? getSeoPageDescription()}
      />

      <MetaCanonical includeUrlParam />

      <section>
        {blogData?.title && headerImage && (
          <PageHeader
            title={blogData.title}
            subtitle={blogData.subtitle}
            image={headerImage}
            hasSmallTitle
          />
        )}

        <StyledContainer>
          {blogPostCardLists?.map((blogPostList, index) => {
            if (!blogPostList?.blogPosts) {
              return null
            }

            const blogPosts = blogPostList.blogPosts
              .map((post) =>
                post?.__typename === 'CraftBlogPostEntry' ? post : undefined,
              )
              .filter(stripMaybes)

            return (
              <BlogPostListWrapper key={index}>
                <SectionHeader title={blogPostList?.blockTitle} hasLargeTitle />

                <PostListMobile>
                  <Slider blogPosts={blogPosts} slidesToShow={1.25} />
                </PostListMobile>

                <PostListTablet>
                  <Slider blogPosts={blogPosts} slidesToShow={2.5} />
                </PostListTablet>

                <PostListDesktop>
                  {blogPostList?.blogPosts.length > 3 ? (
                    <Slider blogPosts={blogPosts} slidesToShow={3} showArrows />
                  ) : (
                    <Grid itemCount={blogPostList.blogPosts.length}>
                      {blogPostList.blogPosts.map((blogPost, index) => {
                        const post =
                          blogPost?.__typename === 'CraftBlogPostEntry'
                            ? blogPost
                            : undefined

                        const postImage = post?.image?.[0]

                        if (
                          !post?.title ||
                          !post.cardIntroduction ||
                          !postImage
                        ) {
                          return null
                        }

                        return (
                          <InformationCard
                            key={index}
                            title={post?.title}
                            date={post?.dateCreated}
                            image={postImage}
                            richText={post?.cardIntroduction}
                            readMoreLines={2}
                            readMoreText={
                              post?.readMoreAlternative ?? undefined
                            }
                            url={post.uri ?? undefined}
                          />
                        )
                      })}
                    </Grid>
                  )}
                </PostListDesktop>
              </BlogPostListWrapper>
            )
          })}

          {craftDataBlogPosts?.length !== 0 && (
            <div ref={blogGridRef}>
              <SectionHeader
                title={<Trans>View all posts</Trans>}
                hasLargeTitle
              />

              <BlogPostWrapper>
                {isLoadingBlogEntries ? (
                  <LoaderWrapper>
                    <StyledLoader />{' '}
                  </LoaderWrapper>
                ) : (
                  <Grid itemCount={blogEntries?.length ?? 0}>
                    {blogEntries?.map((post, index) => {
                      const postImage = post?.image?.[0]

                      if (
                        !post?.title ||
                        !post.cardIntroduction ||
                        !postImage
                      ) {
                        return null
                      }

                      return (
                        <InformationCard
                          key={index}
                          title={post?.title}
                          date={post?.dateCreated}
                          image={postImage}
                          richText={post?.cardIntroduction}
                          readMoreLines={2}
                          readMoreText={post?.readMoreAlternative ?? undefined}
                          url={post.uri ?? undefined}
                        />
                      )
                    })}
                  </Grid>
                )}
              </BlogPostWrapper>

              <StyledBlogPagination
                currentPageIndex={currentPageIndex}
                pageCount={blogPageCount}
                setCurrentPageIndex={setCurrentPageIndex}
              />
            </div>
          )}
        </StyledContainer>
      </section>
    </>
  )
}

export default Blog
