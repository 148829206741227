import { gql, TypedDocumentNode, ApolloClient, useQuery } from '@apollo/client'

import { CraftBlogPostCardFragment } from '../../../lib/craftBlogFragments'
import { CraftImageFragment } from '../../../lib/craftFragments'
import { getCraftSiteProps } from '../../../lib/getCraftSiteProps'
import { ResolvedRoute } from '../../../lib/resolveRoute'
import useLocale from '../../../lib/useLocale'
import {
  BlogPostsQuery,
  BlogPostsQueryVariables,
  BlogQuery,
  BlogQueryVariables,
} from './useBlog.generated'

export interface CraftBlogStaticData {
  craftDataBlog: Exclude<BlogQuery['entry'], null> | undefined
  craftDataBlogPosts: Exclude<BlogPostsQuery['blogEntries'], null> | undefined
  resolvedRoute?: ResolvedRoute
}

const blogQuery = gql`
  query Blog($site: [String], $preferSites: [CraftQueryArgument]) {
    entry(
      type: "blogOverview"
      site: $site
      preferSites: $preferSites
      unique: true
    ) {
      id
      uid

      ... on CraftBlogOverviewEntry {
        title
        subtitle
        image {
          ...CraftImageFragment
          desktopUrl: url(width: 2500, height: 500)
          tabletUrl: url(width: 1982, height: 600)
          mobileUrl: url(width: 1534, height: 800)
        }

        metaTitle
        metaDescription

        blogPostCards {
          ... on CraftBlogPostCardsBlockEntry {
            blockTitle
            blogPosts {
              ...CraftBlogPostCardFragment
            }
          }
        }
      }
    }
  }

  ${CraftImageFragment}
  ${CraftBlogPostCardFragment}
` as TypedDocumentNode<BlogQuery, BlogQueryVariables>

export const blogPostsQuery = gql`
  query BlogPosts(
    $site: [String]
    $preferSites: [CraftQueryArgument]
    $limit: Int
    $offset: Int
  ) {
    blogEntries(
      site: $site
      preferSites: $preferSites
      unique: true
      limit: $limit
      offset: $offset
    ) {
      ...CraftBlogPostCardFragment
    }
  }

  ${CraftBlogPostCardFragment}
` as TypedDocumentNode<BlogPostsQuery, BlogPostsQueryVariables>

export function useBlogEntries(limit?: number, offset?: number) {
  const locale = useLocale()

  const { data, loading } = useQuery(blogPostsQuery, {
    variables: {
      ...getCraftSiteProps(locale),
      limit: limit ?? null,
      offset: offset ?? null,
    },
  })

  return {
    blogEntries: data?.blogEntries,
    isLoading: loading,
  }
}

export const getStaticProps = async (
  client: ApolloClient<unknown>,
  resolvedRoute: ResolvedRoute,
  locale?: string,
): Promise<CraftBlogStaticData> => {
  const { data: blogData } = await client.query({
    query: blogQuery,
    variables: {
      ...getCraftSiteProps(locale),
    },
  })

  const { data: blogPostsData } = await client.query({
    query: blogPostsQuery,
    variables: {
      ...getCraftSiteProps(locale),
      offset: null,
      limit: null,
    },
  })

  return {
    craftDataBlog: blogData?.entry ?? undefined,
    craftDataBlogPosts: blogPostsData.blogEntries ?? undefined,
    resolvedRoute,
  }
}

export default getStaticProps
